import React, { useContext, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import { Layout } from '../component/Layout'
import { PropertyList } from '../component/page_component/PropertyList'
import { graphql, PageProps, useStaticQuery } from 'gatsby'
import { LifeStyle } from '../component/page_component/LifeStyle'
import { LifeStlyePageSlider } from '../component/LifeStlyePageSlider'
import { makeStyles } from '@material-ui/core/styles'

import { useIntl } from 'gatsby-plugin-intl'
import { SearchContext } from '../contexts/searchContext'
import SeoContent from '../component/page_component/SeoContent'
import Faq from '../component/page_component/faq'
import propretyType from '../data/propretyType.json'
import { LIFESTYLE } from '../utils/constants'
import ContactForm from '../component/page_component/contact/contactForm'
import PreFooterMenu from '../component/page_component/contact/preFooterMenu'
import { useLocation } from '@reach/router'

const useStyles = makeStyles((theme) => ({
  lifestyleSlider: {
    marginTop: theme.spacing(20),
    marginBottom: theme.spacing(10),
  },
}))

const LifestyleShowcase: React.FC<PageProps> = (props: any) => {
  const {
    location,
    pageContext: { data, types, currentType, region, departement, country },
  } = props
  const intl = useIntl()
  const classes = useStyles()
  const { setSearch, flushSearchState } = useContext(SearchContext)

  const getNestedData = (data: any, lang: string, key: string) => {
    return data?.[lang]?.[key] || null
  }

  const allTypes = [
    {
      type: "Appartment", data: ["Appartment",
        "Cave",
        "ChambreDeService",
        "Duplex",
        "Penthouse",
        "PlateauAAmenager",
        "Souplex",
        "Studio",
        "Triplex",
        "immeuble art deco",
        "bhausmann",
        "Building",
        "Box",
        "Garage",
        "ParkingColl",
        "ParkingExt",
        "Parking",
        "TerrABatir",
        "Terrain"
      ]
    },
    {
      type: "Chalet", data: ['Chalet']
    },
    {
      type: "Villa", data: ['Villa', 'Riad']
    },
    {
      type: "HotelParticulier", data: ['HotelParticulier', 'MaisMaitre']
    },
    {
      type: "Castle", data: ["Castle",
        "Mansion",
        "Fort",
        "Monastery",
        "Palace",
        "PropChasse",
        "PropViticole",
        "PropEquestre",
        "Propriete",
        "Forest",
        "Terrain",
        "TerrViticole",
        "Etang",
        "TerrLoisirs",
        "TerrNonConstruct"]
    },
    {
      type: "Loft", data: ['Loft', 'AtelierDArtiste']
    },
    {
      type: "Mas", data: ["Mas",
        "Bastide",
        "CorpsDeFerme",
        "TerrAgricole",
        "TerrViticole",
        "TerrABatir",
        "Terrain",
        "MaisProvencale"]
    },
    {
      type: "House", data: ["Chaumiere",
        "CorpsDeFerme",
        "Ferme",
        "Fermette",
        "Grange",
        "Longere",
        "MaisBourgeoise",
        "MaisContemporaine",
        "MaisArchitecte",
        "MaisMaitre",
        "MaisonPecheur",
        "MaisonVille",
        "MaisLandaise",
        "MaisNormand",
        "MaisProvencale",
        "MaisTradi",
        "House",
        "Mazet",
        "Palace",
        "Pavillon",
        "PropChasse",
        "PropViticole",
        "PropEquestre",
        "Propriete",
        "EchoppeBordelaise",
        "Boat",
        "MobilHome",
        "Peniche",
        "IlePrivee",
        "TerrABatir",
        "Terrain"
      ]
    },

  ]

  const result = allTypes.find(item => item.type === currentType);
  const myDataList = result?.data

  const propsData = getNestedData(propretyType, intl.locale, currentType.toLowerCase())
  const faq = propsData?.content?.faq || []
  const sections = propsData?.content?.sections || []
  const title = propsData?.content?.title || ''
  const subtitle = propsData?.content?.description || ''
  const loc = useLocation();

  useEffect(() => {
    if (loc?.hash === "#no-update") {

    } else {
      flushSearchState()
      setSearch({ name: "propertyType", value: myDataList })
    }

  }, [loc?.hash])

  let slideData = {
    title: '',
    mainImg: '',
    mainImgAlt: '',
    leftImg: '',
    leftImgAlt: '',
    rightImg: '',
    rightImgAlt: '',
    details: '',
  }

  const HOME_PAGE_QUERY = graphql`
  query  {
    site {
      siteMetadata {
        siteUrl
      }
    }

  allPropertyDistinct:allProperty {
    distinct(field: propertyType)
  }  
  }
`

  slideData = {
    title: currentType,
    details: 'details',
    leftImg: 'domains_casteles_left.png',
    rightImg: 'domains_casteles_right.png',
    mainImg: 'domains_casteles_main.png',
    leftImgAlt: 'domains_casteles_left.png',
    rightImgAlt: 'domains_casteles_right.png',
    mainImgAlt: 'domains_casteles_main.png',
  }

  const {

    allPropertyDistinct: { distinct },
  } = useStaticQuery(HOME_PAGE_QUERY)

  const desiredOrder = [
    'Appartment',
    'House',
    'Villa',
    'HotelParticulier',
    'Castle',
    'Loft',
    'Chalet',
    'Mas',
  ].filter(el => el != currentType)



  // Filter the list to include only items in desiredOrder and sort by desiredOrder
  const orderedList = distinct
    .filter((item: string) => desiredOrder.includes(item))
    .sort((a: string, b: string) => desiredOrder.indexOf(a) - desiredOrder.indexOf(b))


  return (
    <Layout location={location} showFlotingMenu={false} title={slideData.title + ' - Kretz'}>
      {/* <Grid container justifyContent="center" id="lifestyleShowcase-top-dream-destination">
        <LifeStlyePageSlider
          mainImgAlt={slideData.mainImgAlt}
          rightImgAlt={slideData.rightImgAlt}
          leftImgAlt={slideData.leftImgAlt}
          leftImg={slideData.leftImg}
          rightImg={slideData.rightImg}
          mainImg={slideData.mainImg}
          details={slideData.details}
          title={slideData.title}
        />
      </Grid>
      <Grid container justifyContent="center" id="lifestyleShowcase-bottom-dream-destination">
        <PropertyList bgClr="#E0E5DE" data={data} location={location} />
      </Grid>
      <Grid container justifyContent="center" className={classes.lifestyleSlider}>

      </Grid> */}
      <Grid container justifyContent="center" id="ifestyleShowcase-top-dream-destination">
        <PropertyList
          filterBackgroundColor={'#fff'}
          data={data}
          location={location}
          title={title}
          description={subtitle}
          withFilter={true}
          disablePropretyTypeFilter={true}
          image={LIFESTYLE[currentType.toLowerCase() as keyof typeof LIFESTYLE]?.mainImg || ''}
        />
      </Grid>
      <LifeStyle data={orderedList} />
      <ContactForm
        title={intl.formatMessage({ id: 'new.Formulaire de contact' })}
        subtitle={intl.formatMessage({ id: 'new.Résultats de recherche_4' })}
        backgroundColor="#fff"
      />
      <SeoContent sections={sections}></SeoContent>
      <Faq items={faq} currentType={currentType} />
      <PreFooterMenu />
    </Layout>
  )
}
export default LifestyleShowcase
